import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FilterUtils } from 'primeng/api';
import { JobGradingService } from 'src/services/job-grading.service';
import { FaqPopupComponent } from '../faq-popup/faq-popup.component';
import { DialogService } from 'primeng/api';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class FAQComponent implements OnInit {
  faqData:any;
  cols:any[];
  apiResp:any;
  @Input() isAdmin: boolean = false;
  constructor(private router:Router, private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService, public dialogService: DialogService) { }

  ngOnInit() {
    this.cols = [
      { field: 'frequentlyAskedQuestionID', header: 'FAQ ID' },
      { field: 'question', header: 'Question' },
      { field: 'answer', header: 'Answer' },
      { field: 'orderSequence', header: 'Order Sequence' }
    ];
    this.getFAQ();
    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return parseInt(filter) > value;
    }
  }
  getFAQ() {
    this.spinner.show();
    this.service.getFAQ().subscribe(response => {
      this.apiResp = response;
      this.faqData = this.apiResp;
      this.spinner.hide();
    })
  }
  editFAQ(selectedfaqData) {
    const ref = this.dialogService.open(FaqPopupComponent, {
      data: {
        selectedFAQData: selectedfaqData,
        faqData: this.faqData,
        edit: true
      },
      header: 'Edit FAQ',
      width: '70%'
    });
    ref.onClose.subscribe(() => {
      this.getFAQ();
    })
  }

  deleteFAQ(frequentlyAskedQuestionID:any){
    this.spinner.show();
    var modifiedBy =sessionStorage.getItem('userId');
    this.service.deleteUFAQ(frequentlyAskedQuestionID,modifiedBy).subscribe(response =>
    {
        let apiResponse = JSON.stringify(response);
        console.log(apiResponse);
        if(!apiResponse.includes("Deleted Successfully"))
        {this.toastr.error(apiResponse,'Error!')}
        else{
        this.toastr.success(apiResponse,'Success!');
        }
   });
   this.spinner.hide();
   this.getFAQ();      
  }

  newFAQ() {
    const ref = this.dialogService.open(FaqPopupComponent, {
      data: {
        selectedFAQData: {},
        faqData: this.faqData,
        edit: false
      },
      header: 'New FAQ',
      width: '70%'
    });
    ref.onClose.subscribe(() => {
      this.getFAQ();
    })
  }
}
