import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questionnaire-selection',
  templateUrl: './questionnaire-selection.component.html',
  styleUrls: ['./questionnaire-selection.component.css']
})
export class QuestionnaireSelectionComponent implements OnInit {

  quesTypes: any[];
  quesType = "";
  constructor() { 
    this.quesTypes = [
      { quesType: "ICQ", quesTitle: "Questionnaire for Individual Positions" },
      { quesType: "LSQ", quesTitle: "Questionnaire for Leadership Positions" },
      { quesType: "PMQ", quesTitle: "Questionnaire for Project Management Positions" }
    ] 
  }

  ngOnInit() {
  }

}
