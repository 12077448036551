import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FilterUtils } from 'primeng/api';
import { DialogService } from 'primeng/api';
import { JobGradingService } from 'src/services/job-grading.service';
import { UserPopupComponent } from '../user-popup/user-popup.component';

@Component({
  selector: 'app-user-screen',
  templateUrl: './user-screen.component.html',
  styleUrls: ['./user-screen.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class UserScreenComponent implements OnInit {

  userData: any;
  cols: any[];
  apiResp: any;
  constructor(private router: Router, private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService, public dialogService: DialogService) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'basfUserID', header: 'BASF ID' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'email', header: 'Email' },
      // { field: 'country', header: 'Country' },
      { field: 'orgCode', header: 'OrgCode' },
      { field: 'positionTitle', header: 'Designation' },
      { field: 'roleName', header: 'Role' },
      // { field: 'assignedUser', header: 'Assigned User' }
    ];
    this.getUsers();
    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      return parseInt(filter) > value;
    }
  }

  getUsers() {
    this.spinner.show();
    this.service.getUsersWithRoles().subscribe(response => {
      this.apiResp = response;
      this.userData = this.apiResp;
      this.spinner.hide();
    })
  }

  editUser(selectedUserData) {
    console.log(selectedUserData,"in edit user")
    const ref = this.dialogService.open(UserPopupComponent, {
      data: {
        selectedUserData: selectedUserData,
        usersData: this.userData,
        edit: true,
        disable:false
      },
      header: 'Edit User',
      width: '70%'
    });
    ref.onClose.subscribe(() => {
      this.getUsers();
    })
  }

  showUser(selectedUserData: any){
    console.log(selectedUserData,"in show user");
    const ref = this.dialogService.open(UserPopupComponent,{
      data: {
        selectedUserData: selectedUserData,
        usersData: this.userData,
        edit: true,
        disable:true
      },
      header: 'User Details',
      width: '70%'
    });
  }

  newUser() {
    const ref = this.dialogService.open(UserPopupComponent, {
      data: {
        selectedUserData: {},
        usersData: this.userData,
        edit: false,
        disable:false
      },
      header: 'New User',
      width: '70%'
    });
    ref.onClose.subscribe(() => {
      this.getUsers();
    })
  }

  deleteUser(userId:any){
    this.spinner.show();
    var modifiedBy =sessionStorage.getItem('userId');
    this.service.deleteUser(userId,modifiedBy).subscribe(response =>
    {
        let apiResponse = JSON.stringify(response);
        console.log(apiResponse);
        if(!apiResponse.includes("Deleted Successfully"))
        {this.toastr.error(apiResponse,'Error!')}
        else{
        this.toastr.success(apiResponse,'Success!');
        }
   });
   this.spinner.hide();
   this.getUsers();      
  }

}
