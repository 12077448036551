import { Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

export class HistoricEVService {
  getvalHisEV: any;
  constructor(private _http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getEv() {
    return this.getvalHisEV;
  } setEv(val) {
    this.getvalHisEV = val;
  }
  getEvHistory(req: any) {
    return this._http.post(`${environment.getEvHistory}`, JSON.stringify(req), httpOptions).pipe(map((response) => response));
  }
}
