import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { JobGradingService } from 'src/services/job-grading.service';

@Component({
  selector: 'app-faq-popup',
  templateUrl: './faq-popup.component.html',
  styleUrls: ['./faq-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FaqPopupComponent implements OnInit {

  orgFAQData: any;
  faqData = {
    'frequentlyAskedQuestionID': 0,
    'question': '',
    'answer': '',
    'orderSequence': 0,
    'actionBy':'',
    'action':'',
    validQuestion: true,
    validAnswer: true,
    validOrderSequence: true,
  };
  faqsData: any;
  apiRequest = {
    faqs: [],
  }
  tempAPIResp: any;
  apiResp: any;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }


  ngOnInit() {
    this.spinner.show();
    this.faqData.actionBy = sessionStorage.getItem('userId');
    if (this.config.data.edit) {
      this.orgFAQData = this.config.data.selectedFAQData;
      this.faqData.question = this.orgFAQData.question;
      this.faqData.answer = this.orgFAQData.answer;
      this.faqData.orderSequence = this.orgFAQData.orderSequence;
      this.faqData.frequentlyAskedQuestionID = this.orgFAQData.frequentlyAskedQuestionID;
      this.faqData.action = 'Update';
    }
    this.faqsData = this.config.data.faqData;
    this.generatedFAQData();
    this.spinner.show();
  }

  submitFAQ() {
    if (this.faqData.question != '' && this.faqData.answer != '' ) {
      this.faqData.frequentlyAskedQuestionID = 0;
      this.faqData.actionBy = sessionStorage.getItem('userId');
      this.faqData.action = 'Insert';
      this.faqData.orderSequence=Number(this.faqData.orderSequence);
      // this.apiRequest.faqs.push(this.faqData);
      this.service.updateFAQ(this.faqData).subscribe(response => {
        this.toastr.success("FAQ Added Successfully", "Success!");
        this.ref.close();
      });
    }
    else {
      this.toastr.warning("Please Enter Required Fields", "Warning!");
    }
  }
  updateFAQ() {
    //this.apiRequest.faqs.push(this.faqData);
    this.faqData.orderSequence=Number(this.faqData.orderSequence);
    this.service.updateFAQ(this.faqData).subscribe(response => {
      this.toastr.success("FAQ Updated Successfully", "Success!");
      this.ref.close();
    });
  }

  generatedFAQData() {
    if (this.faqsData) {
      this.faqData = JSON.parse(JSON.stringify(this.faqData));
    }
  }


  cancel(){
    this.faqData = {
        'frequentlyAskedQuestionID': 0,
        'question': '',
        'answer': '',
        'orderSequence': 0,
        'actionBy':'',
        'action':'',
        validQuestion: true,
        validAnswer: true,
        validOrderSequence: true,
      };
  }
  Reset(){
    if (this.config.data.edit) {
      this.orgFAQData = this.config.data.selectedFAQData;
      this.faqData.question = this.orgFAQData.question;
      this.faqData.answer = this.orgFAQData.answer;
      this.faqData.orderSequence = this.orgFAQData.orderSequence;
    }
  }
  checkQuestionValidation() {  
    if(this.faqData.question ==''){
      this.faqData.validQuestion =false;      
    }else{
      this.faqData.validQuestion =true;
    }
  }
  checkAnswerValidation() {  
    if(this.faqData.answer ==''){
      this.faqData.validAnswer =false;      
    }else{
      this.faqData.validAnswer =true;
    }
  }
}
