import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { JobGradingService } from '../../../services/job-grading.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/api';
import { FinalgradeSummaryPopupComponent } from '../finalgrade-summary-popup/finalgrade-summary-popup.component';

@Component({
  selector: 'app-modify-finalgrade-summary',
  templateUrl: './modify-finalgrade-summary.component.html',
  styleUrls: ['./modify-finalgrade-summary.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class ModifyFinalgradeSummaryComponent implements OnInit {
  @Input() recQuesType;
  quesType = "";
  categoryId = "";
  quesText =  "";
  finalgradingsummaryData: any;
  orgfinalgradingsummaryData: any
  
  constructor(private router: Router, private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService, public dialogService: DialogService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.getFinalGradingSummary();
  }
  ngOnInit() {
    this.getFinalGradingSummary();
  }
  getFinalGradingSummary() {
    this.spinner.show();
    this.quesType = this.recQuesType;
    this.generateQuestionText(this.quesType);
    this.service.getFinalGradingSummary(this.categoryId).subscribe(response => {
      this.finalgradingsummaryData = response;    
      this.finalgradingsummaryData.sort((a,b) => a.jobGrade < b.jobGrade ? 1 : -1);
      this.finalgradingsummaryData.forEach((q,qIndex) => {
        q.modifiedData = false;
        q.modifiedSummary = false;
        q.modifiedAccountability = false;
        q.modifiedImpact = false;
        q.modifiedContextOfWork = false;
        q.modifiedKnowledgeAndExperience = false;
      });
      this.orgfinalgradingsummaryData = JSON.parse(JSON.stringify(this.finalgradingsummaryData));
      
      this.spinner.hide();
    })
  }

  generateQuestionText(quesChoice) {
    if (quesChoice == 'LSQ') {
      this.categoryId = "3";
    } else if (quesChoice == 'ICQ') {
      this.categoryId = "2";
    } else if (quesChoice == 'PMQ') {
      this.categoryId = "4";
    }
  }
  editFinalGradeSummary(qData) {
  
    const ref = this.dialogService.open(FinalgradeSummaryPopupComponent, {
      data: {
        qData: qData
      },
      header: 'Final Grade Summary',
      width: '70%'
    });

    ref.onClose.subscribe((rData) => {
      if (rData) {
        this.updateFinalGradeData(rData);
      }
    });
  }
  updateFinalGradeData(recData) {
    this.spinner.show();
    this.finalgradingsummaryData.forEach(q => {
      if(q.finalGradeSummaryID === recData.finalGradeSummaryID) {
        if(q.summaryDescription !== recData.summaryDescription) {
          q.modifiedData = true;
          q.modifiedSummary = true;
          q.summaryDescription = recData.summaryDescription;
        }
        if(q.accountability !== recData.accountability) {
          q.modifiedData = true;
          q.modifiedAccountability = true;
          q.accountability = recData.accountability;
        }
        if(q.impact !== recData.impact) {
          q.modifiedData = true;
          q.modifiedImpact = true;
          q.impact = recData.impact;
        }
        if(q.contextOfWork !== recData.contextOfWork) {
          q.modifiedData = true;
          q.modifiedContextOfWork = true;
          q.contextOfWork = recData.contextOfWork;
        }
        if(q.knowledgeAndExperience !== recData.knowledgeAndExperience) {
          q.modifiedData = true;
          q.modifiedKnowledgeAndExperience = true;
          q.knowledgeAndExperience = recData.knowledgeAndExperience;
        }
        this.toastr.success("Saved", "Success!");
      }
    });   
    this.spinner.hide();
  }
  sumbitFinalGradeSummary() {
    let FinalGradeSummary = [];
    this.finalgradingsummaryData.forEach((qd) => {
      if (qd.modifiedData) {
        qd.modifiedBy=sessionStorage.getItem('userId');

        FinalGradeSummary.push(qd);
        console.log(FinalGradeSummary);
      }
    });
    let gObj = {
      "FinalGradeSummary": FinalGradeSummary
    };
    this.service.UpdateFinalGradeSummary(gObj).subscribe(response => {
      this.getFinalGradingSummary();
      this.toastr.success("Updated Successfully", "Success!")
    })
  }

  cancel() {
    this.finalgradingsummaryData = JSON.parse(JSON.stringify(this.orgfinalgradingsummaryData));
    this.toastr.success("Changes Reverted", "Success!")
  }
}
