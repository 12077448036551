import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-finalgrade-summary-popup',
  templateUrl: './finalgrade-summary-popup.component.html',
  styleUrls: ['./finalgrade-summary-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FinalgradeSummaryPopupComponent implements OnInit {
  sData: any;
  tFinalGradeData: any;
  gradingQuestionCategoryID: any;
  jobGrade: any;
  finalGradeSummaryData = {
    'finalGradeSummaryID': 0,
    'gradingQuestionCategoryID':0,
    'summaryDescription': '',
    'accountability': '',
    'impact': '',
    'contextOfWork': '',
    'knowledgeAndExperience': '',
    'modifiedBy':'0',
    validsummaryDescription: true,
    validaccountability: true,
    validimpact: true,
    validcontextOfWork: true,
    validknowledgeAndExperience: true
  };
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
    this.sData = this.config.data.qData;
    this.finalGradeSummaryData.gradingQuestionCategoryID = this.sData.gradingQuestionCategoryID;
    this.finalGradeSummaryData.finalGradeSummaryID=this.sData.finalGradeSummaryID;
    this.finalGradeSummaryData.summaryDescription=this.sData.summaryDescription;
    this.finalGradeSummaryData.accountability=this.sData.accountability;
    this.finalGradeSummaryData.impact=this.sData.impact;
    this.finalGradeSummaryData.contextOfWork=this.sData.contextOfWork;
    this.finalGradeSummaryData.knowledgeAndExperience=this.sData.knowledgeAndExperience;
    
    this.generateFinalGradeData();
  }

  generateFinalGradeData() {
    this.tFinalGradeData = JSON.parse(JSON.stringify(this.sData));
    this.jobGrade = this.tFinalGradeData.jobGrade;
    this.finalGradeSummaryData.gradingQuestionCategoryID = this.tFinalGradeData.gradingQuestionCategoryID;
    this.finalGradeSummaryData.finalGradeSummaryID=this.tFinalGradeData.finalGradeSummaryID;
    this.finalGradeSummaryData.summaryDescription=this.tFinalGradeData.summaryDescription;
    this.finalGradeSummaryData.impact=this.tFinalGradeData.impact;
    this.finalGradeSummaryData.accountability=this.tFinalGradeData.accountability;
    this.finalGradeSummaryData.contextOfWork=this.tFinalGradeData.contextOfWork;
    this.finalGradeSummaryData.knowledgeAndExperience=this.tFinalGradeData.knowledgeAndExperience;
    
  }
  cancel() {    
    this.generateFinalGradeData();
  }

  saveFinalGrade() {
    if(this.finalGradeSummaryData.validsummaryDescription==true && this.finalGradeSummaryData.validaccountability==true
      &&this.finalGradeSummaryData.validimpact==true && this.finalGradeSummaryData.validcontextOfWork==true && this.finalGradeSummaryData.validknowledgeAndExperience==true){
 
    this.tFinalGradeData.jobGrade = this.jobGrade;
    this.tFinalGradeData.gradingQuestionCategoryID = this.finalGradeSummaryData.gradingQuestionCategoryID;
    this.tFinalGradeData.finalGradeSummaryID=this.finalGradeSummaryData.finalGradeSummaryID;
    this.tFinalGradeData.summaryDescription=this.finalGradeSummaryData.summaryDescription;
    this.tFinalGradeData.accountability=this.finalGradeSummaryData.accountability;
    this.tFinalGradeData.impact=this.finalGradeSummaryData.impact;
    this.tFinalGradeData.contextOfWork=this.finalGradeSummaryData.contextOfWork;
    this.tFinalGradeData.knowledgeAndExperience=this.finalGradeSummaryData.knowledgeAndExperience;
    
    this.ref.close(this.tFinalGradeData);
  }
}
  checkSummaryDescValidation(){
    if(this.finalGradeSummaryData.summaryDescription==''){
      this.finalGradeSummaryData.validsummaryDescription=false;
    }else{
      this.finalGradeSummaryData.validsummaryDescription=true;
    }
  }
  checkAccountabilityValidation(){
    if(this.finalGradeSummaryData.accountability==''){
      this.finalGradeSummaryData.validaccountability=false;
    }else{
      this.finalGradeSummaryData.validaccountability=true;
    }
  }
  checkImpactValidation(){
    if(this.finalGradeSummaryData.impact==''){
      this.finalGradeSummaryData.validimpact=false;
    }else{
      this.finalGradeSummaryData.validimpact=true;
    }
  }
  checkContextOfWorkValidation(){
    if(this.finalGradeSummaryData.contextOfWork==''){
      this.finalGradeSummaryData.validcontextOfWork=false;
    }else{
      this.finalGradeSummaryData.validcontextOfWork=true;
    } 
  }
  checkknowledgeAndExperienceValidation(){
    if(this.finalGradeSummaryData.knowledgeAndExperience==''){
      this.finalGradeSummaryData.validknowledgeAndExperience=false;
    }else{
      this.finalGradeSummaryData.validknowledgeAndExperience=true;
    } 
  }
}
