import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { JobGradingService } from '../../../services/job-grading.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/api';
import { QuestionDataComponent } from '../question-data/question-data.component';

@Component({
  selector: 'app-modify-grading-questions',
  templateUrl: './modify-grading-questions.component.html',
  styleUrls: ['./modify-grading-questions.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class ModifyGradingQuestionsComponent implements OnInit {
  @Input() recQuesType;
  quesType = "";
  categoryId = "";
  
  quesText =  "";
  questionsData: any;

  orgQuestionData: any
  
  ratingAnswerCount = 0;
  ratingQuestionIndex: any;
  isAdmin:boolean=false;

  constructor(private router: Router, private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService, public dialogService: DialogService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.getGradingQuestions();
  }
  ngOnInit() {
    this.getGradingQuestions();
    this.isAdmin = sessionStorage.getItem("role").includes('Admin');
  }

  getGradingQuestions() {
    this.spinner.show();
    this.quesType = this.recQuesType;
    this.generateQuestionText(this.quesType);
    this.service.getGradingQuestions(this.categoryId).subscribe(response => {
      this.questionsData = response;
      this.questionsData.forEach((q,qIndex) => {
        q.modifiedData = false;
        q.dispExmPos = false;
        q.dispNote = false;
        q.answered = false;
        q.index = qIndex + 1;
        q.modifiedQuestion = false;
        if (q.supportingText.indexOf('Note') != -1) {
          q.dispNote = true;
        }
        q.gradingAnswerOptions.forEach((a, index) => {
          a.modifiedOptName = false;
          a.modifiedOptValue = false;
          a.modifiedOptDesc = false;
          a.modifiedOptDef = false;
          a.modifiedOptWEP = false;
          q.showMore = false;
          if (a.gradingAnswerRanges.length > 0) {
            a.level1 = a.gradingAnswer.split('/')[0];
            a.level2 = a.gradingAnswer.split('/ ')[1];
            q.mandatory = true;
            q.dispExmPos = false;
            q.spclQuestion = true;
            this.ratingQuestionIndex = q.index;
            this.ratingAnswerCount = index + 1;
          } else {
            q.mandatory = true;
            if (a.examplePosition != "") {
              q.dispExmPos = true;
            }
          }
        })
      })
      this.orgQuestionData = JSON.parse(JSON.stringify(this.questionsData));
      this.spinner.hide();
    })
  }

  generateQuestionText(quesChoice) {
    if (quesChoice == 'LSQ') {
      this.categoryId = "3";
    } else if (quesChoice == 'ICQ') {
      this.categoryId = "2";
    } else if (quesChoice == 'PMQ') {
      this.categoryId = "4";
    }
  }

  editQuestion(qData) {
    const ref = this.dialogService.open(QuestionDataComponent, {
      data: {
        qData: qData
      },
      header: 'Edit Question',
      width: '70%'
    });

    ref.onClose.subscribe((rData) => {
      if (rData) {
        this.updateQuestionData(rData);
      }
    });
  }

  updateQuestionData(recData) {
    this.spinner.show();
    this.questionsData.forEach(q => {
      if(q.gradingQuestionID === recData.gradingQuestionID) {
        if(q.question !== recData.question) {
          q.modifiedQuestion = true;
          q.modifiedData = true;
          q.question = recData.question;
        }
        q.gradingAnswerOptions.forEach((a, index) => {
          let tModifications = this.checkForModifications(a, recData.gradingAnswerOptions[index]);
          a.modifiedOptName = tModifications.modifiedOptName;
          if (a.modifiedOptName) {
            a.gradingAnswer = recData.gradingAnswerOptions[index].gradingAnswer;
            q.modifiedData = true;
          }
          a.modifiedOptValue = tModifications.modifiedOptValue;
          if (a.modifiedOptValue) {
            a.answerValue = parseFloat(recData.gradingAnswerOptions[index].answerValue);
            q.modifiedData = true;
          }
          a.modifiedOptDesc = tModifications.modifiedOptDesc;
          if (a.modifiedOptDesc) {
            a.answerDiscription = recData.gradingAnswerOptions[index].answerDiscription;
            q.modifiedData = true;
          }
          a.modifiedOptDef = tModifications.modifiedOptDef;
          if (a.modifiedOptDef) {
            a.answerDefinition = recData.gradingAnswerOptions[index].answerDefinition;
            q.modifiedData = true;
          }
          a.modifiedOptWEP = tModifications.modifiedOptWEP;
          if (a.modifiedOptWEP) {
            a.examplePosition = recData.gradingAnswerOptions[index].examplePosition;
            q.modifiedData = true;
          }
        });
        this.toastr.success("Saved", "Success!")
      }
    });
    this.spinner.hide();
  }

  checkForModifications(gradingAnswerOption, recGradingAnswerOption) {
     let modifiedOptName = recGradingAnswerOption.gradingAnswer !== gradingAnswerOption.gradingAnswer;
     let modifiedOptValue = recGradingAnswerOption.answerValue !== gradingAnswerOption.answerValue;
     let modifiedOptDesc = recGradingAnswerOption.answerDiscription !== gradingAnswerOption.answerDiscription;
     let modifiedOptDef = recGradingAnswerOption.answerDefinition !== gradingAnswerOption.answerDefinition;
     let modifiedOptWEP = recGradingAnswerOption.examplePosition !== gradingAnswerOption.examplePosition;

     return {modifiedOptName, modifiedOptValue, modifiedOptDesc, modifiedOptDef, modifiedOptWEP};
  }

  sumbitQuestions() {
    let gradingQuestions = [];
    this.questionsData.forEach((qd) => {
      if (qd.modifiedData) {
        gradingQuestions.push(qd);
      }
    });
    let gObj = {
      "gradingQuestions": gradingQuestions
    };
    this.service.updateGradingQuestions(gObj).subscribe(response => {
      this.getGradingQuestions();
      this.toastr.success("Updated Successfully", "Success!")
    })
  }

  cancel() {
    this.questionsData = JSON.parse(JSON.stringify(this.orgQuestionData));
    this.toastr.success("Changes Reverted", "Success!")
  }

}
