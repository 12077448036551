import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finalgrade-summary',
  templateUrl: './finalgrade-summary.component.html',
  styleUrls: ['./finalgrade-summary.component.css']
})
export class FinalgradeSummaryComponent implements OnInit {

  quesTypes: any[];
  quesType = "";
  constructor() { 
    this.quesTypes = [
      { quesType: "ICQ", quesTitle: "Questionnaire for Individual Positions" },
      { quesType: "LSQ", quesTitle: "Questionnaire for Leadership Positions" },
      { quesType: "PMQ", quesTitle: "Questionnaire for Project Management Positions" }
    ] 
  }

  ngOnInit() {
  }


}
