import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HistoricEVService } from '../../../services/historicEV.service';
import { JobGradingService } from '../../../services/job-grading.service';

@Component({
  selector: 'app-historicEv-Summary',
  templateUrl: './historicEv-Summary.component.html',
  styleUrls: ['./historicEv-Summary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HistoricEvSummaryComponent implements OnInit {
  inpData: any;
  datasource: any;
  userId: any;
  gradingSummaryData:any;
  isAdmin:boolean=false;
  @ViewChild('reportContent', { static: true }) reportContent: ElementRef;
  constructor(private router: Router, private service: JobGradingService,private _service: HistoricEVService) {
    
  }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    this.isAdmin = sessionStorage.getItem('role').includes('Admin');
    if (this.userId != null && this.userId != undefined) {
      this.inpData = this._service.getEv();
      if (this.inpData != undefined && this.inpData != null) {
        let inp = {
          "Mode": 1,
          "uniqueid": this.inpData["uid"]
        }
        this._service.getEvHistory(inp).subscribe(response => {
          this.datasource = response;
        });
        
        
        this.service.getGradingSummary(this.inpData["uid"]).subscribe(response => {
          this.gradingSummaryData = response;
          this.gradingSummaryData.sort((a, b) => a.jobGrade > b.jobGrade ? -1 : a.jobGrade < b.jobGrade ? 1 : 0)
          
        })
      } else {
        this.router.navigate(['/historic-evaluation']);
      }
    } else {
      this.router.navigate(['/login-test']);
    }
  }
  

  downloadPdf() {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title></title><style>@page {size: A4;}body{font-family:Arial;font-size:12px;}table{width:100%;}table span{display:block;width:100%;background:#004890;color:#fff;line-hieght:25px;font-size:14px;}table td{vertical-align:top !important;}#tblnxt{ page-break-inside: avoid }</style>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('');
    var strEv = "<table>";
    // strEv = strEv + "<tr><td colspan='2'><span>Evaluation Details</span></td></tr>";
    
    //strEv = strEv + "<tr><td  style='width:35%'><strong>Hiring Manager</strong></td><td>" + this.inpData.hiringManager + "</td></tr>";
    // strEv = strEv + "<tr><td><strong>Org Code</strong></td><td>" + this.inpData.hiringManagerOrgCode + "</td></tr>";
    // strEv = strEv + "<tr><td><strong>Designation</strong></td><td>" + this.inpData.hiringManagerPositionTitle + "</td></tr>";
    // strEv = strEv + "<tr><td><strong>Date of Evaluation</strong></td><td>" + this.inpData.date + "</td></tr>";
    strEv = strEv + "</table><br/><table>";
    strEv = strEv + "<tr><td colspan='2'><span>Position Details</span></td></tr>";
    strEv = strEv + "<tr><td style='width:35%'><strong>Operational Manager</strong></td><td>" + this.inpData.operationalManagerName+ "</td></tr>";
    strEv = strEv + "<tr><td style='width:35%'><strong>Manager Position Title</strong></td><td>" + this.inpData.operationalManagerPositionTitle+ "</td></tr>";
    strEv = strEv + "<tr><td style='width:35%'><strong>Manager OrgCode</strong></td><td>" + this.inpData.operationalManagerOrgCode+ "</td></tr>";
    strEv = strEv + "<tr><td style='width:35%'><strong>Position Title</strong></td><td>" + this.inpData.inCumbentPositionTitle + "</td></tr>";
    strEv = strEv + "<tr><td><strong>Incumbent Name</strong></td><td>" + this.inpData.inCumbentName + "</td></tr>";
    if(this.inpData.inCumbentPositionID!="")
    strEv = strEv + "<tr><td><strong>Position Id</strong></td><td>" + this.inpData.inCumbentPositionID + "</td></tr>";
    strEv = strEv + "<tr><td><strong>Org Code</strong></td><td>" + this.inpData.inCumbentOrgCode + "</td></tr>";
    strEv = strEv + "<tr><td><strong>Job Family</strong></td><td>" + this.inpData.jobFamily + "</td></tr>";
    strEv = strEv + "<tr><td><strong>Job SubFamily</strong></td><td>" + this.inpData.jobSubFamily + "</td></tr>";
    strEv = strEv + "<tr><td><strong>Role</strong></td><td>" + this.inpData.globalPositionRole + "</td></tr>";
    strEv = strEv + "<tr><td><strong>position Country</strong></td><td>" + this.inpData.positionCountry + "</td></tr>";
    strEv = strEv + "<tr><td><strong>Region</strong></td><td>" + this.inpData.inCumbentRegion+ "</td></tr>";
    strEv = strEv + "<tr><td><strong>Division</strong></td><td>" + this.inpData.inCumbentDivision + "</td></tr>";
    
    
    strEv = strEv + "</table><br/><table>";
    strEv = strEv + "<tr><td colspan='2'><strong>Is the position part of a project organization or created because of a project ?</strong> : " + this.inpData.isExistingPosition + "</td></tr>";
    strEv = strEv + "<tr><td colspan='2'><strong>Is the main character of the position Leadership, may it be disciplinary, professional, operational, administrative and/or functional ?</strong> : " + this.inpData.isVacant + "</td></tr>";
    strEv = strEv + "</table>";
    if (this.datasource != undefined) {
      for (let i = 0; i < this.datasource.length; i++) {

        if (i == 0) {
          strEv = strEv + "<br/><table><tr><td colspan='2'><span>" + this.datasource[0].qstTitle + "</span></td></tr>";
        }
        strEv = strEv + "<tr><td><strong>" + this.datasource[i].jobgrade + ") " + this.datasource[i].questionDefinition + "</strong> : " + this.datasource[i].answerDiscription + "</td></tr>";
      }
    }
    strEv = strEv + "</table><br/><table><tr><td><span>Position Summary</span></td></tr><tr><td>" + this.inpData.jobGradingSummary + "</td></tr>";
    strEv = strEv + "</table><br/><table id='tblnxt'><tr><td><span>Evaluation Result</span></td></tr><tr><td><span><b>Tendency Grade</b> = Based on your responses, the evaluation result has a tendency towards the second job grade shown.</span></td></tr><tr><td><table border='1' style='border-collapse: collapse;'><tr> <th colspan='2'>Position Level</th> <th>Accountability</th> <th>Impact</th> <th>Context of Work</th> <th>Knowledge & Experience</th> </tr><tr id='cust - header'> <th style='text - align: center!important;' >Grade</th> <th>Summary Description</th> <th style='text - align: left!important; '> <p>Describes the responsibilites related to own job</p><p>- Scope of own work</p><p>- Degree of guidelines/supervision</p><p>- Project management, where applicable</p></th> <th style='text - align: left!important; '> <p>Describes impact on business reults as well as impact on others:</p><p>- Business impact of own actions</p><p>- Impact on others</p><p>- Communications/ negotation</p></th> <th style='text - align: left!important; '> <p>Describes the nature of problems and situations to be resolved:</p><p>- Type of situations</p><p>- Way of solving it</p><p>Includes unpredictability and novelty/ uniqueness of situations</p></th> <th style='text - align: left!important; '> <p> The educational background/knowledge listed below (depth & breath) is typically required to perform at the respective career stage. </p><p> However, the educational background of the jobholder alone will Not justify a maping to this career stage </p></th> </tr>";
    this.gradingSummaryData.forEach(gds => {
      strEv = strEv + "<tr><td>" + gds.jobGrade + this.gradeReturn(gds.isFinalGrade) + "</td><td>"
      + gds.summaryDescription + "</td><td>"
      + gds.accountability + "</td><td>"
      + gds.impact + "</td><td>"
      + gds.contextOfWork + "</td><td>"
      + gds.knowledgeAndExperience + "</td></tr>"
    })
    
    strEv = strEv + "</table></td></tr></table>";
    
    strEv = strEv + "<br/><label class='col-sm-1 col-form-label cust-label' style='font-weight:bold'>Comments:</label><label class='col-form-label'>"+this.inpData.comments+"</label>";

    mywindow.document.write(strEv);
    mywindow.document.write('</body></html>');
    mywindow.document.title = this.inpData.uniqueid;
    
    mywindow.document.close();     mywindow.focus(); 

    mywindow.print();
    mywindow.close();

    return true;

  }

  downloadAttachment() {
    const decodedstring = atob(this.gradingSummaryData[0].attachmentContent.toString());
    const byteNumbers = new Array(decodedstring.length);
    for (let i = 0; i < decodedstring.length; i++) {
      byteNumbers[i] = decodedstring.charCodeAt(i);

    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: this.gradingSummaryData[0].attachmentType });

    var url = URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = this.gradingSummaryData[0].attachmentName;
    anchor.href = url;
    anchor.click();
  }

  navToHistoricEval() {
    this.router.navigate(['/historic-evaluation']);
  }

  navToHome() {
    this.router.navigate(['/home']);
  }

  gradeReturn(gradeValue) {
    if(gradeValue) {
      let gradeText = "[Primary Grade]";
      return gradeText;
    } else {
      let tendencyText = "[Tendency Grade]";
      return tendencyText;
    }
  }

  
}
