import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GovernanceComponent implements OnInit {
  userId: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    if (!(this.userId != null && this.userId != 'undefined' && this.userId != '')){
      this.router.navigate(['/login']);
    }
  }

}
