import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './_helpers/httpconfig.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { JobPostingFormComponent } from './components/job-posting-form/job-posting-form.component';
import { HiringManagerComponent } from './components/hiring-manager/hiring-manager.component';
import { HiringResourceComponent } from './components/hiring-resource/hiring-resource.component';
import { LoginTestComponent } from './components/login-test/login-test.component';
import { GradingQuestionsComponent } from './components/grading-questions/grading-questions.component';
import { SummaryComponent } from './components/summary/summary.component';
import { GradingScreenComponent } from './components/grading-screen/grading-screen.component';
import { LoginComponent } from './components/login/login.component'
import { HistoricEvComponent } from './components/historicEv/historicEv.component';
import { HistoricEvSummaryComponent } from './components/historicEv-Summary/historicEv-Summary.component';
import { GovernanceComponent } from './components/governance/governance.component';
import { QuestionnaireSelectionComponent } from './components/questionnaire-selection/questionnaire-selection.component';
import { ModifyGradingQuestionsComponent } from './components/modify-grading-questions/modify-grading-questions.component';
import { QuestionDataComponent } from './components/question-data/question-data.component';
import { UserScreenComponent } from './components/users/user-screen/user-screen.component';
import { UserPopupComponent } from './components/users/user-popup/user-popup.component';
import { SapComponent } from './components/sap/sap.component';
import { FinalgradeSummaryComponent } from './components/finalgrade-summary/finalgrade-summary.component';
import { ModifyFinalgradeSummaryComponent } from './components/modify-finalgrade-summary/modify-finalgrade-summary.component';
import { FinalgradeSummaryPopupComponent } from './components/finalgrade-summary-popup/finalgrade-summary-popup.component';
import { FAQComponent } from './components/faq/faq.component';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { CardModule } from 'primeng/card';

import { HistoricEVService } from '../services/historicEV.service';
import { AuthenticationServiceService } from '../services/authentication-service.service';
import { DragDropDirective } from '../services/drag-drop.directive';
import { JobFamilyComponent } from './components/job-family/job-family.component';
import { LogoutComponent } from './components/logout/logout.component';
import { FaqPopupComponent } from './components/faq-popup/faq-popup.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    JobPostingFormComponent,
    HiringManagerComponent,
    HiringResourceComponent,
    LoginTestComponent,
    HistoricEvComponent,
    HistoricEvSummaryComponent,
    GradingQuestionsComponent,
    SummaryComponent,
    GradingScreenComponent,
    LoginComponent,
    GovernanceComponent,
    QuestionnaireSelectionComponent,
    ModifyGradingQuestionsComponent,
    QuestionDataComponent,
    UserScreenComponent,
    UserPopupComponent,
    SapComponent,
    DragDropDirective,
    FinalgradeSummaryComponent,
    ModifyFinalgradeSummaryComponent,
    FinalgradeSummaryPopupComponent,
    FAQComponent,
    JobFamilyComponent,
    LogoutComponent,
    FaqPopupComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TableModule,
    AccordionModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    ToastrModule,
    NgxSpinnerModule,
    TabViewModule,
    DynamicDialogModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    AutoCompleteModule,
    ListboxModule,
    DropdownModule,
    FileUploadModule,
    CardModule
  ],
  providers: [    
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: httpInterceptor, multi: true },
  
    CookieService,
    HistoricEVService,
    AuthenticationServiceService
  ],
  bootstrap: [AppComponent],

  entryComponents: [
    QuestionDataComponent,
    UserPopupComponent,
    FinalgradeSummaryPopupComponent,
    FaqPopupComponent
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule { }
