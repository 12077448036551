import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Observable, Subject } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class JobGradingService {

  private userSubject = new Subject<any>();
  private nav_menuSubject = new Subject<boolean>();

  accessObj = {
    "historyEvaluationAccess": true,
    "governanceEvaluationAccess": false
  };
  constructor(private _http: HttpClient) { }

  setNavmenustatus(status: boolean) {
    this.nav_menuSubject.next(status);

  }

  getNavmenustatus(): Observable<boolean>{
    return this.nav_menuSubject.asObservable();
  }

  getUsers() {
    return this._http.get(environment.getUsers + 'all').pipe(map((response) => response));
  }


  getUsersWithRoles() {
    return this._http.get(environment.getUserInfoWithRoles + 'all').pipe(map((response) => response));
  }

  getUserInfo(data) {
    return this._http.get(`${environment.getUserInfo}${data}&SelfDetails=true`).pipe(map((response) => response));
  }
  getUserReportees(data) {
    return this._http.get(`${environment.getUserReportees}${data}&SelfDetails=true`).pipe(map((response) => response));
  }
  getUserReporting(data) {
    return this._http.get(`${environment.getUserReporting}${data}&SelfDetails=true`).pipe(map((response) => response));
  }

  getHRUserInfo(data) {
    return this._http.get(`${environment.getUserInfo}${data}&SelfDetails=false&LoginAsHR=true`).pipe(map((response) => response));
  }

  getGradingDetails(userId) {
    return this._http.get(`${environment.getGradingDetails}${userId}`).pipe(map((response) => response));
  }
  getJobFamilyDetails(langId, unitId) {
    return this._http.get(`${environment.getJobFamily}languageID=${langId}&businessunitID=${unitId}`).pipe(map((response) => response));
  }
  getgetGlobalPositionRoleDetails(langId, unitId) {
    return this._http.get(`${environment.getGlobalPositionRole}languageID=${langId}&businessunitID=${unitId}`).pipe(map((response) => response));
  }

  getJobCountryDetails(countryId) {
    return this._http.get(`${environment.getCountryList}${countryId}`).pipe(map((response) => response));
  }
  getGradingQuestions(categoryId) {
    return this._http.get(`${environment.getGradingQuestions}${categoryId}`).pipe(map((response) => response));
  }
  getFinalGradingSummary(categoryId) {
    return this._http.get(`${environment.getFinalGradingSummary}${categoryId}`).pipe(map((response) => response));
  }
  getPositionSummary(data: any) {
    return this._http.post(environment.getSummary, data).pipe(map((response) => response))
  }  

  getGradingSummary(data: any) {
    return this._http.get(`${environment.getGradingSUmmary}${data}`).pipe(map((response) => response));
  }

  sendUserAccessValues(data: any) {
   this.accessObj = {
      "historyEvaluationAccess": data.historyEvaluationAccess,
      "governanceEvaluationAccess": data.governanceEvaluationAccess
    };
    this.userSubject.next(this.accessObj);
  }

  getUserAccessValues(): Observable<any> {
    return this.userSubject.asObservable();
  }

  getDecryptedUserDetails(data) {
    return this._http.get(`${environment.decryptUserDetails}${data}`).pipe(map((response) => response));
  }
  getSAMLService() {
    return this._http.get(environment.getSAMLURL).pipe(map((response) => response));
  }

  manageHistoryDetails(jobGradingID,addHistory) {
    return this._http.get(`${environment.manageHistroryDetails}jobGradingID=${jobGradingID}&addHistory=${addHistory}`).pipe(response => response);
  }

  updateGradingQuestions(data) {
    return this._http.post(environment.updateGradingQuestions, data).pipe(map((response) => response))
  }
  updateGradingComments(data) {
    return this._http.post(environment.updateGradingComments, data).pipe(map((response) => response))
  }
  UpdateFinalGradeSummary(data) {
    return this._http.post(environment.UpdateFinalGradeSummary, data).pipe(map((response) => response))
  }

  updateUser(data) {
    return this._http.post(environment.updateUserInfo, data).pipe(map((response) => response))
  }

  uploadFileData(data) {
    return this._http.post(environment.uploadFile, data).pipe(map((response) => response))
  }
  
  uploadJobFileData(data) {
    return this._http.post(environment.uploadGlobalJobData, data).pipe(map((response) => response))
  }

  downloadTempalte() {
    return this._http.post(environment.downloadTemplate,'').pipe(map((response) => response));
  }
  insertLoginAudit(data: any) {
    return this._http.get(`${environment.insertLoginAudit}${data}`).pipe(map((response) => response));
  }
  getFAQ() {
    return this._http.get(environment.getFAQ).pipe(map((response) => response));
  }
  updateFAQ(data) {
    return this._http.post(environment.updateFAQInfo, data).pipe(map((response) => response))
  }
  deleteUser(UserId: any,ModifiedBy: any){
    return this._http.delete(`${environment.deleteUser}UserId=${UserId}&ModifiedBy=${ModifiedBy}`).pipe(response => response);
  }
  deleteUFAQ(frequentlyAskedQuestionID: any,ModifiedBy: any){
    return this._http.delete(`${environment.deleteFAQ}FrequentlyAskedQuestionID=${frequentlyAskedQuestionID}&ModifiedBy=${ModifiedBy}`).pipe(response => response);
  }
}
