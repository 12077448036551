import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JobGradingService } from 'src/services/job-grading.service';
import { AuthenticationServiceService } from '../../../services/authentication-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  decryptedUser: any;
  encryptedUser: any;
  department: any;
  apiResp: any;
  userNotFound = false;
  tokenInfo:any;
  loginAudiInfo:any;

  constructor(private _appServ: JobGradingService, private authservice: AuthenticationServiceService, private spinner: NgxSpinnerService, private toast: ToastrService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.spinner.show();
    this.decryptedUser = sessionStorage.getItem('userId'); 
    this.route.queryParams.subscribe(params => {
      this.encryptedUser = params['Username'];
      if (this.decryptedUser == "undefined" || this.decryptedUser == undefined || this.decryptedUser == null || this.decryptedUser == "null" || this.decryptedUser == "" || this.decryptedUser != sessionStorage.getItem('userId')) {
        if (this.encryptedUser == "undefined" || this.encryptedUser == undefined || this.encryptedUser == null || this.encryptedUser == "null" || this.encryptedUser == "") {
          this.SAMLService();
        } else {

          this._appServ.getDecryptedUserDetails(this.encryptedUser).subscribe(response => {
          this.apiResp = response;
           
            sessionStorage.setItem('userId', this.apiResp);    
            
            this.decryptedUser = sessionStorage.getItem('userId');

            this.authservice.getToken(this.decryptedUser).subscribe(response => {
              this.tokenInfo=response;
              localStorage.setItem('TokenInfo', JSON.stringify(this.tokenInfo));
            });;

            this._appServ.insertLoginAudit(this.decryptedUser).subscribe(response => {
              this.loginAudiInfo=response;              
            });;            

            this.verifyUserAuthorization(this.decryptedUser);
            
          },      (error) => {
            this.toast.error(error, "Error!");
            this.spinner.hide();
          });
        }
      } else {
        this.verifyUserAuthorization(this.decryptedUser);
      }
     
    });
}

  SAMLService() {
    this.spinner.show();
    this._appServ.getSAMLService().subscribe((result) => {
      this.spinner.hide();
      window.location.href = result.toString();
    },
      (error) => {
        this.toast.error(error, "Error!");
        this.spinner.hide();
      });
  }

  verifyUserAuthorization(userID) {
    this._appServ.getUserInfo(userID).subscribe((res:any[]) => {
      console.log(res, "login page");
      if (res.length==0) {
        this.userNotFound = true;
        this._appServ.setNavmenustatus(false);
      }
      else {
        this._appServ.setNavmenustatus(true);
        this.router.navigate(['/home']);
      }
    })
  }
}
