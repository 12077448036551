import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HistoricEVService } from '../../../services/historicEV.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-historicEv',
  templateUrl: './historicEv.component.html',
  styleUrls: ['./historicEv.component.css'],
  styles: [`
        :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
            position: -webkit-sticky;
            position: sticky;
            top: 69px;
        }

        @media screen and (max-width: 64em) {
            :host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
                top: 99px;
            }
        }
`],
 encapsulation: ViewEncapsulation.None
})
export class HistoricEvComponent implements OnInit {
  userId: any;
  cols: any;
  role: any;
  datasource: any;
  filteredFields = [];
  filteredData: any;
  tempFilteredData: any;
  constructor(private router: Router, private _service: HistoricEVService) {
   }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    this.role = sessionStorage.getItem('role');
    if (this.userId != null && this.userId != undefined) {
      
        let inp = {
          "Mode": 0,
          "uniqueid": 0,
          "userid": this.userId,
          "role": this.role
        }
        this._service.getEvHistory(inp).subscribe(response => {
          this.datasource = response;
          this.filteredData = JSON.parse(JSON.stringify(this.datasource));
        });
        this.cols = [
          { field: 'uniqueid', header: 'Unique ID',width:'300px' },
          { field: 'date', header: 'Date', width: '100px' },
          { field: 'operationalManagerName', header: 'Operational Manager', width: '200px' },
          { field: 'evaluator', header: 'Evaluator', width: '200px' },
        ];
      
    } else {
      this.router.navigate(['/login-test']);
    }
  }
  SelectView(data) {
    
    this._service.setEv(data);
    this.router.navigate(['/home/historic-summary']);
  }
  filterData(value, filter) {
    console.log(value, filter);
    if (value == '') {
      if (this.filteredFields.length) {
        this.filteredFields = this.filteredFields.filter(ff => ff.fieldName !== filter);
      } else {
        this.filteredFields = [];
      }
    } else {
      let foundField = this.filteredFields.filter(ff => ff.fieldName == filter);
      if (foundField.length) {
        this.filteredFields.forEach(ff => {
          if (ff.fieldName == filter) {
            ff.value = value;
          }
        })
      } else {
        let fieldObj = {
          fieldName: filter,
          value: value
        };
        this.filteredFields.push(fieldObj);
      }
    }
    if (this.filteredFields.length) {
      let temp = this.datasource;
      this.filteredFields.forEach(ff => {
        temp = temp.filter(fd => fd[ff.fieldName].toUpperCase().includes(ff.value.toUpperCase()));
      })
      this.filteredData = temp;
    } else {
      this.filteredData = JSON.parse(JSON.stringify(this.datasource));
    }
    this.tempFilteredData = JSON.parse(JSON.stringify(this.filteredData));
  }

  exportExcel() {
    let tFilteredData = JSON.parse(JSON.stringify(this.filteredData));
    this.exportAsExcelFile(tFilteredData, 'EvaluationHistory', 'sheet1', this.cols)
  }
  exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    var fieldValues: any[] = columnValues.map(field => field.field);
    var headerValues: string[] = columnValues.map(header => header.header);
    var displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        var jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        var jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
