import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  enableBg = true;
  userAccess = false;

  unAuthorised = false;
  constructor(
    private router: Router
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      console.log(event.url);
      if(event.url == '/home' || event.url == '/') {
        this.enableBg = true;
      } else {
        this.enableBg = false;
      }
      if(event.url == '/login-test' || event.url == '/login' || event.url == '/logout') {
        this.userAccess = false;
        sessionStorage.setItem('governanceEvaluationAccess', "false");
      } else {
        this.userAccess = true;
      }
    });
  }
  title = 'app';
}
