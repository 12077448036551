import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-question-data',
  templateUrl: './question-data.component.html',
  styleUrls: ['./question-data.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuestionDataComponent implements OnInit {

  qData: any;
  tQuestData: any;
  questionId: any;
  questionTitle: any;
  options: any[];
  validQuestionTitle = true;


  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
    this.qData = this.config.data.qData;
    this.questionId = this.qData.gradingQuestionID;
    this.generateQuestionData();
  }

  generateQuestionData() {
    this.tQuestData = JSON.parse(JSON.stringify(this.qData));
    this.questionTitle = this.tQuestData.question;
    this.options = JSON.parse(JSON.stringify(this.qData.gradingAnswerOptions));
    this.options.forEach(option => {
      option.validName = true;
      option.validValue = true;
      option.validDefinition = true;
      option.validDescription = true;
      option.validWorkExample = true;
    })
  }

  cancel() {
    this.validQuestionTitle = true;
    this.generateQuestionData();
  }

  saveQuestion() {
    this.tQuestData.question = this.questionTitle;
    this.tQuestData.gradingAnswerOptions = [];
    this.tQuestData.gradingAnswerOptions = this.options;
    this.ref.close(this.tQuestData);
  }

  checkQuestValidation() {
    this.validQuestionTitle = this.questionTitle ? true : false;
  }

  checkNameValidation(optionId, value) {
    this.options.forEach(option => {
      if(option.gradingAnswerOptionID === optionId) {
        option.validName = this.textValidation(value);
      }
    })
  }

  checkDefValidation(optionId, value) {
    this.options.forEach(option => {
      if(option.gradingAnswerOptionID === optionId) {
        option.validDefinition = this.textValidation(value);
      }
    })
  }

  checkDescValidation(optionId, value) {
    this.options.forEach(option => {
      if(option.gradingAnswerOptionID === optionId) {
        option.validDescription = this.textValidation(value);
      }
    })
  }

  checkWEPValidation(optionId, value) {
    this.options.forEach(option => {
      if(option.gradingAnswerOptionID === optionId) {
        option.validWorkExample = this.textValidation(value);
      }
    })
  }

  checkValueValidation(optionId, value) {
    this.options.forEach(option => {
      if(option.gradingAnswerOptionID === optionId) {
        option.validValue = !isNaN(value);
      }
    })
  }

  textValidation(value) {
    if (value) {
      return true;
    } else {
      return false;
    }
  }
}
