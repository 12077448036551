import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { JobGradingService } from 'src/services/job-grading.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryComponent implements OnInit {

  summaryData: any = {};
  q1Value = "";
  quesType = "";
  gradingData = [];
  gradingID: any;

  apiResp: any;
  pos:boolean;
  constructor(private router: Router, private spinner: NgxSpinnerService, private service: JobGradingService) { }

  ngOnInit() {
    if (!history.state.data) {
      this.router.navigate(['/home']);
    } else {
      this.spinner.show();
      this.summaryData = history.state.data.positionSummaryData;
      this.quesType = history.state.data.quesType;
      this.gradingData = history.state.data.gradingData;
      if(this.gradingData[0].positionID=="")
      {
        this.pos=false;
      }else{
        this.pos=true;
      }
      this.spinner.hide();
    }
  }

  navigateToGradingHistory() {
    let jobGradingId = this.summaryData['jobGradingHistoryID'];
    let addHistory = true;
    this.postManagerHistory(jobGradingId, addHistory);
    this.router.navigate(['home/grading-summary'], { state: { data: { gId: this.summaryData['jobGradingHistoryID'] } } });
  }

  navigateToQuestionnaire() {
    let jobGradingId = this.summaryData['jobGradingHistoryID'];
    let addHistory = false;
    this.postManagerHistory(jobGradingId, addHistory);
    this.router.navigate(['home/grading-questionaire'], { state: { data: { quesType: this.quesType, gradingData: this.gradingData[0] } } });
  }

  postManagerHistory(jobGradingId, addHistory) {
    this.service.manageHistoryDetails(jobGradingId, addHistory).subscribe(response => {
      this.apiResp = response;
    });
  }

}
