import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { JobGradingService } from 'src/services/job-grading.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  shownavmenu: boolean = true;
  @Input() userAccess;
  accessObj = {
    "historyEvaluationAccess": true,
    "governanceEvaluationAccess": false
  };
  constructor(private service: JobGradingService) { }

  ngOnChanges(changes: SimpleChanges) {
    if(this.userAccess) {
      setInterval(() => {this.accessObj.governanceEvaluationAccess = (sessionStorage.getItem("governanceEvaluationAccess") == "true") ? true : false}, 1000);
    } else {
      this.accessObj.governanceEvaluationAccess = false;
    }
  }
  ngOnInit() {
    this.service.getNavmenustatus().subscribe((res: boolean) => {
      this.shownavmenu = res;
    })
  }
 
}
