import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { JobGradingService } from '../../../services/job-grading.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grading-questions',
  templateUrl: './grading-questions.component.html',
  styleUrls: ['./grading-questions.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GradingQuestionsComponent implements OnInit {

  quesType = "";
  categoryId = "";
  quesText =  "";
  questionsData: any;
  questionnaireResp = [];
  gradingData = [];
  summaryReq = {
    "gradingHistory": [],
    "questionnaireResponse": []
  };
  summaryResp: any;
  ratingAnswered = false;

  ratingAnswerCount = 0;
  ratingAnsweredCount = 0;
  ratingQuestionIndex: any;
  validatedQuestion = false;
  constructor(private router: Router, private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService) {
  }

  ngOnInit() {
    if (!history.state.data) {
      this.router.navigate(['/home']);
    } else {
      this.getGradingQuestions();
    }
  }

  getGradingQuestions() {
    this.spinner.show();
    this.quesType = history.state.data.quesType;
    history.state.data.gradingData.createdBy = sessionStorage.getItem('userId');

    this.gradingData.push(history.state.data.gradingData);
    this.generateQuestionText(this.quesType);
    this.service.getGradingQuestions(this.categoryId).subscribe(response => {
      this.questionsData = response;
      this.questionsData.forEach((q,qIndex) => {
        q.dispExmPos = false;
        q.dispNote = false;
        q.answered = false;
        q.index = qIndex + 1;
        if (q.supportingText.indexOf('Note') != -1) {
          q.dispNote = true;
        }
        q.gradingAnswerOptions.forEach((a, index) => {
          if (a.gradingAnswerRanges.length > 0) {
            a.level1 = a.gradingAnswer.split('/')[0];
            a.level2 = a.gradingAnswer.split('/ ')[1];
            q.mandatory = true;
            q.dispExmPos = false;
            this.ratingQuestionIndex = q.index;
            this.ratingAnswerCount = index + 1;
          } else {
            q.mandatory = true;
            if (a.examplePosition != "") {
              q.dispExmPos = true;
            }
          }
        })
      })
      this.spinner.hide();
    })
  }

  generateQuestionText(quesChoice) {
    if (quesChoice == 'LSQ') {
      this.categoryId = "3";
      this.quesText = "Questionnaire for Leadership Positions";
    } else if (quesChoice == 'ICQ') {
      this.categoryId = "2";
      this.quesText = "Questionnaire for Individual Contributor Positions";
    } else if (quesChoice == 'PMQ') {
      this.categoryId = "4";
      this.quesText = "Questionnaire for Project Management Positions (with Leadership)";
    }
  }

  pushQuestionResponse(questOption) {
    let ansObj = {
      "gradingQuestionID": questOption.gradingQuestionID,
      "selectedAnswerOptions": [
        {
          "gradingAnswerOptionID": questOption.gradingAnswerOptionID,
          "rangeID": 0
        }
      ]
    };

    let fAnsObj = this.questionnaireResp.filter(q => q.gradingQuestionID === questOption.gradingQuestionID);
    if (fAnsObj.length == 0) {
      this.questionnaireResp.push(ansObj);
      this.modifyAnsweredValue(questOption.gradingQuestionID);
    } else {
      this.questionnaireResp.forEach(q => {
        if (q.gradingQuestionID === questOption.gradingQuestionID) {
          q.selectedAnswerOptions[0].gradingAnswerOptionID = questOption.gradingAnswerOptionID;
        }
      })
    }
  }

  modifyAnsweredValue(qId) 
  {
    this.questionsData.forEach(qt => {
      if(qt.gradingQuestionID == qId) {
        qt.answered = true;
      }
    })
  }

  pushRatingAnswerResponse(qId, aId, rangeID) {
    this.ratingAnswered = true;
    let ratingAnsObj = {
      "gradingQuestionID": qId,
      "selectedAnswerOptions": [
        {
          "gradingAnswerOptionID": aId,
          "rangeID": rangeID
        }
      ]
    };
    let fAnsObj = this.questionnaireResp.filter(q => q.gradingQuestionID === qId);
    if (fAnsObj.length == 0) {
      this.questionnaireResp.push(ratingAnsObj);
      this.modifyAnsweredValue(qId);
      this.ratingAnsweredCount = this.ratingAnsweredCount + 1;
    } else {
      let fAnsObj1 = fAnsObj[0].selectedAnswerOptions.filter(g => g.gradingAnswerOptionID === aId);
      (fAnsObj1.length == 0) ? this.pushQuestinnaireResp(qId, aId, rangeID) : this.modifyQuestionnaireResp(qId, aId, rangeID)
    }
  }

  pushQuestinnaireResp(qId, aId, rangeID) {
    this.questionnaireResp.forEach((q,index) => {
      if (q.gradingQuestionID == qId) {
        let tObj = {
          "gradingAnswerOptionID": aId,
          "rangeID": rangeID
        }
        q.selectedAnswerOptions.push(tObj);
        this.ratingAnsweredCount = this.ratingAnsweredCount + 1;
      }
    })
  }

  modifyQuestionnaireResp(qId, aId, rangeID) {
    this.questionnaireResp.forEach(q => {
      if (q.gradingQuestionID == qId) {
        q.selectedAnswerOptions.forEach(a => {
          if (a.gradingAnswerOptionID == aId) {
            a.rangeID = rangeID
          }
        })
      }
    })
  }

  submitQuestionnaire() {
    (this.quesType != 'ICQ') ? this.ratingAnsweredFunc() : this.icqTypeFunc();
  }

  // notICQTypeFunc() {
  //   this.ratingAnswered ? this.ratingAnsweredFunc() : this.notRatingAnsweredFunc();
  // }

  icqTypeFunc() {
    (this.questionnaireResp.length != this.questionsData.length) ? this.ratingAnsweredFunc(): this.finalSubmitQuestionnaire();
  }

  ratingAnsweredFunc() {
    if (this.questionnaireResp.length != this.questionsData.length) {
      let msgString = "Answer ";
      this.questionsData.forEach(qu => {
          if (!qu.answered) {
            msgString = msgString + qu.index + ' ,';
          }
      })
      msgString = msgString + "Mandatory Questions";
      this.validatedQuestion = true;
      this.toastr.error(msgString);
    }
    else if (this.ratingAnsweredCount != this.ratingAnswerCount) {
      this.validatedQuestion = true;
      let msgString1 = "Answer All Rating Options for Question: " + this.ratingQuestionIndex;

      this.toastr.error(msgString1);
    }
    else {
      this.finalSubmitQuestionnaire();
    }
  }

  // notRatingAnsweredFunc() {
  //   if (this.questionnaireResp.length != this.questionsData.length - 1) { 
  //     let msgString3 = "Answer ";
  //     this.questionsData.forEach(qu => {
  //       if (!qu.answered && qu.index != this.ratingQuestionIndex) {
  //         msgString3 = msgString3 + qu.index + ' ,';
  //       }
  //     })
  //     msgString3 = msgString3 + "Mandatory Questions";
  //     this.validatedQuestion = true;
  //     this.display(msgString3) 
  //   } else {
  //     this.finalSubmitQuestionnaire();
  //   }
  // }

  display(toastrMsg) {
    this.toastr.error(toastrMsg);
  }

  finalSubmitQuestionnaire() {
    this.spinner.show();
    this.summaryReq.gradingHistory = this.gradingData ;
    this.summaryReq.questionnaireResponse = this.questionnaireResp;
    this.service.getPositionSummary(this.summaryReq).subscribe(response => {
      this.summaryResp = response;
      this.spinner.hide();
      this.router.navigate(['home/grading-questionaire/summary'], { state: { data: { positionSummaryData: this.summaryResp, gradingData: this.gradingData, quesType: this.quesType } } });
    })
  }

}
