import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JobGradingService } from 'src/services/job-grading.service';

@Component({
  selector: 'app-sap',
  templateUrl: './sap.component.html',
  styleUrls: ['./sap.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SapComponent implements OnInit {
  uploadedFiles: any[] = [];
  fileName = "";
  fileType = "";
  fileUpload = false;
  filesize ="";
  fileBuffer: any;
  fileObject: any;
  constructor(private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
  }

  myUploader(event) {
    this.spinner.show();
    let fileData = event[0];
    const element = event[0];
    this.filesize = element.size;
    this.filesize = this.filesize.toString();
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toastr.warning("File Limit Exceeded, Allows only below 3MB data");
      return false;
    } else {
      this.fileName = fileData.name;
      this.fileType = fileData.type;
      this.fileBuffer = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.fileBuffer = fileReader.result;
        this.fileBuffer = this.fileBuffer.replace(`data:${this.fileType};base64,`, '');
        this.fileUpload = true;
        this.fileObject = {
          "fileName": this.fileName,
          "fileType": this.fileType,
          "fileSize": this.filesize,
          "fileAsBase64": this.fileBuffer
        };
        console.log("File Object", this.fileObject);
      }
      fileReader.readAsDataURL(fileData);
    }
    this.uploadedFiles.push(event[0]);
    this.spinner.hide();
  }

  uploadSubmit(fileUpload) {
    this.spinner.show();
    this.service.uploadFileData(this.fileObject).subscribe(response => {
      let apiResponse = JSON.stringify(response);
      console.log(apiResponse);
      if(apiResponse.includes("Invalid template"))
      {this.toastr.error(apiResponse,'Error!')}
      else{
      this.toastr.success(apiResponse,'Success!');
      }
      this.removeFile();
      this.spinner.hide();
    })
  }

  downloadTempalte() {
    this.spinner.show();
    this.service.downloadTempalte().subscribe(response => {
      console.log(response);
      this.spinner.hide();
    })
  }

  removeFile() {
    this.fileName = "";
    this.fileType = "";
    this.filesize = "";
    this.fileBuffer = ""
    this.fileObject = null;
  }


}
