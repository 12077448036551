import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JobGradingService } from 'src/services/job-grading.service';
import { DialogService } from 'primeng/api';

@Component({
  selector: 'app-grading-screen',
  templateUrl: './grading-screen.component.html',
  styleUrls: ['./grading-screen.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class GradingScreenComponent implements OnInit {
  txtComments: string= "";
  gradingHistoryId: any;
  gradingSummaryData: any;
  gradingSummaryDataComments: any;
  cols = [];
  isAdmin: boolean=false;
  public DisabledButton: boolean = false;
  constructor(private router: Router, private service: JobGradingService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    if (!history.state.data) {
      this.router.navigate(['/home']);
    } else {
      this.isAdmin = sessionStorage.getItem('role').includes('Admin');
      this.spinner.show();
      this.gradingHistoryId = history.state.data.gId;
      this.service.getGradingSummary(this.gradingHistoryId).subscribe(response => {
        this.gradingSummaryData = response;
        this.gradingSummaryData.sort((a, b) => a.jobGrade > b.jobGrade ? -1 : a.jobGrade < b.jobGrade ? 1 : 0)
        this.spinner.hide();
      })
    }

  }

  
  downloadPdf() {
    var Comments="";
    Comments=this.gradeReturnComments();
   
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title></title><style>@page {size: A4;}body{font-family:Arial;font-size:12px;}table{width:100%;}table span{display:block;width:100%;background:#004890;color:#fff;line-hieght:25px;font-size:14px;}table td{vertical-align:top !important;}#tblnxt{ page-break-inside: avoid }</style>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('');
    var strEv = "";
    
    strEv = strEv + "<table id='tbnxt'><tr><td><span>Evaluation Result</span></td></tr><tr><td><span><b>Tendency Grade</b> = Based on your responses, the evaluation result has a tendency towards the second job grade shown.</span></td></tr><tr><td><table border='1' style='border-collapse: collapse;'><tr> <th colspan='2'>Position Level</th> <th>Accountability</th> <th>Impact</th> <th>Context of Work</th> <th>Knowledge & Experience</th> </tr><tr id='cust - header'> <th style='text - align: center!important;' >Grade</th> <th>Summary Description</th> <th style='text - align: left!important; '> <p>Describes the responsibilites related to own job</p><p>- Scope of own work</p><p>- Degree of guidelines/supervision</p><p>- Project management, where applicable</p></th> <th style='text - align: left!important; '> <p>Describes impact on business reults as well as impact on others:</p><p>- Business impact of own actions</p><p>- Impact on others</p><p>- Communications/ negotation</p></th> <th style='text - align: left!important; '> <p>Describes the nature of problems and situations to be resolved:</p><p>- Type of situations</p><p>- Way of solving it</p><p>Includes unpredictability and novelty/ uniqueness of situations</p></th> <th style='text - align: left!important; '> <p> The educational background/knowledge listed below (depth & breath) is typically required to perform at the respective career stage. </p><p> However, the educational background of the jobholder alone will Not justify a maping to this career stage </p></th> </tr>";
    this.gradingSummaryData.forEach(gds => {
      strEv = strEv + "<tr><td>" + gds.jobGrade + this.gradeReturn(gds.isFinalGrade) + "</td><td>"
      + gds.summaryDescription + "</td><td>"
      + gds.accountability + "</td><td>"
      + gds.impact + "</td><td>"
      + gds.contextOfWork + "</td><td>"
      + gds.knowledgeAndExperience + "</td></tr>"
      
    })
    
    strEv = strEv + "</table>";

    var strEvComments = "";
    strEvComments = strEvComments + "<table id='tbnxt1'><tr><td><span><b>Comments:</b></span></td>";
    strEvComments = strEvComments +  "<td><b>"+Comments +"</b></td>" ;

    strEvComments = strEvComments + " </tr></table>";
    
    mywindow.document.write(strEv);
    mywindow.document.write(strEvComments);
    mywindow.document.write('</body></html>');
    mywindow.document.title = "Evaluation Result";
    mywindow.document.close();  
    mywindow.focus(); 

    mywindow.print();
    mywindow.close();

    return true;

  }

  gradeReturn(gradeValue) {
    if(gradeValue) {
      let gradeText = "[Primary Grade]";
      return gradeText;
    } else {
      let tendencyText = "[Tendency Grade]";
      return tendencyText;
    }
  }
  gradeReturnComments() {
  this.service.getGradingSummary(this.gradingHistoryId).subscribe(response => {
    this.gradingSummaryDataComments = response;
  });
  return this.gradingSummaryDataComments[0].comments;
}
  navToHistoricEval() {
    this.router.navigate(['/historic-evaluation']);
  }

  navToHome() {
    this.router.navigate(['/home']);
  }
  addComments(event: any) {

    let GradingComments = {
      "GradingAddComments": this.txtComments,
      "gradingHistoryId":this.gradingHistoryId
    };
    this.service.updateGradingComments(GradingComments).subscribe(response => {
     this.toastr.success("Saved Successfully", "Success!");
    
    });
    event.target.disabled = true;
    this.DisabledButton = true;
    // const ref = this.dialogService.open(GradingAddcommentsComponent, {
    //   data: {
    //     gradingHistoryId: this.gradingHistoryId
    //   },
    //   header: 'Add Comments',
    //   width: '70%'
    // });
    // ref.onClose.subscribe((rData) => {
    // });
  }

}
