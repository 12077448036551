// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

 //const BaseUrl ="https://localhost:44361/api";

const BaseUrl = "https://egrading.basf.com/api";
//const BaseUrl = "https://egrading-dev.basf.com/api";
//const BaseUrl = "https://egrading-qa.basf.com/api";
//const BaseUrl = "https://egrade-demo-1.azurewebsites.net/api";

export const environment = {
  production: false,
  getUsers: `${BaseUrl}/Users/getallusers?BASFUserID=`,
  getUserInfo: `${BaseUrl}/Users/GetUserDetails?BASFUserID=`,
  getUserInfoWithRoles: `${BaseUrl}/Users/getalluserswithrole?BASFUserID=`,
  getUserReportees: `${BaseUrl}/Users/GetUserRepoties?BASFUserID=`,
  getUserReporting: `${BaseUrl}/Users/GetUserRepotingTo?BASFUserID=`,
  updateUserInfo:`${BaseUrl}/Users/updateuserdetails`,
  getGradingDetails: `${BaseUrl}/Users/GetGradingDetails?ManagerBASFID=`,
  getJobFamily: `${BaseUrl}/JobFamily/getjobfamily?`,
  getGlobalPositionRole: `${BaseUrl}/JobFamily/getGlobalPositionRole?`,
  getCountryList: `${BaseUrl}/JobFamily/getcountrylist?countryID=`,
  getGradingQuestions: `${BaseUrl}/Grading/getgradingquestions?categoryID=`,
  getSummary: `${BaseUrl}/Grading/insertgradinghistory`,
  getGradingSUmmary: `${BaseUrl}/Grading/getfinalgradesummary?jobGradeHistoryID=`,
  getSAMLURL: `${BaseUrl}/GetSamlURL`, 
  decryptUserDetails: `${BaseUrl}/Users/decryptUserdetails?decryptUser=`,
  manageHistroryDetails: `${BaseUrl}/Grading/managejobgradingdata?`,
  getEvHistory: `${BaseUrl}/Grading/getEvHistory`,
  updateGradingQuestions: `${BaseUrl}/Grading/updategradingquestionanswer`,
  uploadFile: `${BaseUrl}/JobGradingFromSAP/uploadgradingdata`,
  uploadGlobalJobData: `${BaseUrl}/JobGradingFromSAP/uploadglobaljobdata`,
  downloadTemplate: `${BaseUrl}/JobGradingFromSAP/downloadtemplate`,
  UpdateFinalGradeSummary: `${BaseUrl}/Grading/UpdateFinalGradeSummary`,  
  getFinalGradingSummary: `${BaseUrl}/Grading/getfinalgradesummaryById?categoryID=`,
  getToken: `${BaseUrl}/Jwt/GetToken?BASFUserID=`,
  insertLoginAudit: `${BaseUrl}/LoginAudit/InsertLoginAuditDetails?BASFUserID=`,
  updateGradingComments: `${BaseUrl}/Grading/updategradingcomments`,
  getFAQ: `${BaseUrl}/FAQ/getallfaq`,
  deleteFAQ: `${BaseUrl}/FAQ/deletefaq?`,
  updateFAQInfo:`${BaseUrl}/FAQ/managefaqdetails`,
  deleteUser:`${BaseUrl}/Users/DeleteUser?`
};

//const BaseUrl = "https://egrading-qa.basf.com/api";
//export const environment = {
//  production: false,
//  getUsers: `${BaseUrl}/Users?BASFUserID=`,
//  getUserInfo: `${BaseUrl}/Users/GetUserDetails?BASFUserID=`,
//  getGradingDetails: `${BaseUrl}/Users/GetGradingDetails?ManagerBASFID=`,
//  getJobFamily: `${BaseUrl}/JobFamily/getjobfamily?`,
//  getGradingQuestions: `${BaseUrl}/Grading/getgradingquestions?categoryID=`,
//  getSummary: `${BaseUrl}/Grading/insertgradinghistory`,
//  getGradingSUmmary: `${BaseUrl}/Grading/getfinalgradesummary?jobGradeHistoryID=`,
//  getSAMLURL: `https://egrading-qa.basf.com/api/saml/GetSamlURL`,
//  decryptUserDetails: `${BaseUrl}/Users/decryptUserdetails?decryptUser=`,
//  manageHistroryDetails: `${BaseUrl}/Grading/managejobgradingdata?`,
//  getEvHistory: `${BaseUrl}/Grading/getEvHistory`
//};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
