import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { JobGradingService } from '../../../services/job-grading.service';
import { AuthenticationServiceService } from '../../../services/authentication-service.service';
@Component({
  selector: 'app-login-test',
  templateUrl: './login-test.component.html',
  styleUrls: ['./login-test.component.css']
})
export class LoginTestComponent implements OnInit {

  user: any;
  usersData: any;
  userId:any;
  tokenInfo:any;
  loginAudiInfo:any;
  // usersData = [
  //   { userName: "Sriram", userId: "1"},
  //   { userName: "Nandha", userId: "2"},
  //   { userName: "Rakesh", userId: "3"}
  // ]
  constructor(private router: Router, private _service: JobGradingService, private spinner: NgxSpinnerService,private _authservice: AuthenticationServiceService) { 
  }

  ngOnInit() {
    this.spinner.show();
    this._service.getUsers().subscribe(response => {
      this.usersData = response;
      this.spinner.hide();
    });
  }

  loginbtn() {
    sessionStorage.setItem('userId', this.user);
    this.userId = sessionStorage.getItem('userId');
    
    this._authservice.getToken(this.userId).subscribe(response => {
      this.tokenInfo=response;
      localStorage.setItem('TokenInfo', JSON.stringify(this.tokenInfo));
    });;
    this._service.insertLoginAudit(this.userId).subscribe(response => {
      this.loginAudiInfo=response;
      
    });;
    this.router.navigate(['/home']);
  }

}
