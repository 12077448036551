import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hiring-manager',
  templateUrl: './hiring-manager.component.html',
  styleUrls: ['./hiring-manager.component.css']
})
export class HiringManagerComponent implements OnInit {

  userId: any;
  constructor(private router: Router) {
   }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userId');
    if (!(this.userId != 'null' && this.userId != 'undefined')) {
      this.router.navigate(['/login']);
    }
  }

}
