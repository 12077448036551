import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationServiceService } from '../../services/authentication-service.service';
import { JobGradingService } from '../../services/job-grading.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private _service: JobGradingService, private auth: AuthenticationServiceService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    this._service.getUserInfo(sessionStorage.getItem('userId')).subscribe((response: any[]) => {
      console.log(response,"AuthGaurdResp")
      if (response.length > 0) {
        return true;
      }
      else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
        return false;
      }
    })
    return true;
  }
  
}
